// External
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import { Provider as MobxProvider } from "mobx-react";

// Internal
import { setUser } from "../../reducers/ThemeOptions";
import { STORES } from "stores";
import { hydrateStores } from "stores/hydrate";
import Loading from "components/Loading";
import MainWrapper from "./MainWrapper";
import { geoStore } from "stores/geo";
import { request } from "services/request";

function Main({
  colorScheme,
  enableFixedHeader,
  enableFixedSidebar,
  enableFixedFooter,
  enableClosedSidebar,
  closedSmallerSidebar,
  enableMobileMenu,
  enablePageTabsAlt,
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadingData();
  }, []);

  // do fetching of global data on application load
  const loadingData = async () => {
    setLoading(true);
    await hydrateStores();
    setLoading(false);

    const geoStatesRes = await request.get(
      `${process.env.REACT_APP_API_URL}/api/country/geo`
    );
    geoStore.setCountries(geoStatesRes.countries);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MobxProvider store={STORES}>
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <MainWrapper
            width={width}
            colorScheme={colorScheme}
            enableFixedHeader={enableFixedHeader}
            enableFixedSidebar={enableFixedSidebar}
            enableFixedFooter={enableFixedFooter}
            enableClosedSidebar={enableClosedSidebar}
            closedSmallerSidebar={closedSmallerSidebar}
            enableMobileMenu={enableMobileMenu}
            enablePageTabsAlt={enablePageTabsAlt}
          />
        )}
      />
    </MobxProvider>
  );
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (user) => {
      dispatch(setUser(user));
    },
  };
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
