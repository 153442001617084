import { makeObservable, observable, action } from "mobx";
import { request } from "../../services/request";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

class AppStore {
  constructor() {
    makeObservable(this, {
      user: observable,
      getCurrentUser: action,
      login: action,
      logout: action,
      afterHydration: action,
      width: observable,
    });
  }

  user = null;
  width = 0;

  async getCurrentUser() {
    try {
      const user = await request.get(`/api/users/admin/current-user`);
      this.user = user.currentUser;
    } catch (err) {
      this.user = null;
    }
  }

  async login(body) {
    try {
      await request.post(`/api/auth/login`, body);
      window.location.reload();
    } catch (error) {
      toast["error"]("Wrong login or password!");
    }
  }

  async logout() {
    await request.get(`/api/auth/logout`);
    localStorage.removeItem("publicId");
    window.location.reload();
  }

  async afterHydration() {
    await this.getCurrentUser();
  }

  async setWidth(pageWidth) {
    this.width = pageWidth;
  }

  async getSizeChart(id) {
    try {
      const chart = await request.post(`/api/size-chart/read-chart`, {
        categoryChartId: id,
      });
      const headerNames = [
        ...new Set(
          chart.sizeChartParameters.map(
            ({ sizeChartAttribute }) => sizeChartAttribute.attributeName
          )
        ),
      ];

      let unitsOfSize = false;
      if (chart.sizeChartParameters.length) {
        unitsOfSize = chart.sizeChartParameters[0].typeValue === "Cm";
      }

      const array = [];

      chart.sizeChartParameters.map(
        ({ size, sizeChartAttribute, attributeValue }) => {
          const isUniqueSize = array.findIndex(
            (arraySize) => arraySize.size === size
          );

          if (isUniqueSize !== -1) {
            array[isUniqueSize].values.push({
              id: uuidv4(),
              name: sizeChartAttribute.attributeName,
              value: attributeValue,
            });
            return;
          }

          array.push({
            id: uuidv4(),
            size,
            values: [
              {
                id: uuidv4(),
                name: sizeChartAttribute.attributeName,
                value: attributeValue,
              },
            ],
          });
        }
      );

      return {
        chartName: chart.nameChart,
        chartId: chart.chartId,
        headerNames,
        unitsOfSize,
        chartData: array,
        statusChart: chart.statusChart,
        sizeChartCategories: chart.sizeChartCategories,
      };
    } catch (err) {
      toast["error"]("Server error!");
    }
  }
}

export const appStore = new AppStore();
