// External
import axios from "axios";

export class Request {
  response = null;
  contentType = "application/json";

  constructor(options = {}) {
    const { contentType } = options;
    if (contentType) {
      this.contentType = contentType;
    }

    this._client = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": this.contentType,
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      baseURL: `${process.env.REACT_APP_API_URL}`,
    });
  }

  get = async (url) => {
    try {
      this.response = await this._client.get(url);
      return this.response.data;
    } catch (err) {
      throw err;
    }
  };

  post = async (url, body) => {
    try {
      this.response = await this._client.post(url, body);
      return this.response.data;
    } catch (err) {
      throw err;
    }
  };

  put = async (url, body) => {
    try {
      this.response = await this._client.put(url, body);
      return this.response.data;
    } catch (err) {
      throw err;
    }
  };

  delete = async (url) => {
    try {
      this.response = await this._client.delete(url);
      return this.response.data;
    } catch (err) {
      throw err;
    }
  };
}

export const request = new Request();

export const multipartDataRequest = new Request({
  contentType: "multipart/form-data",
});
