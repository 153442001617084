import React from "react";
import { memo } from "react";
import Loader from "react-loaders";
import Logo from "../../assets/images/logo.svg";

const Loading = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-cy" />
      </div>
      <h6 className="mt-3">
        <img src={Logo} alt="" />
      </h6>
    </div>
  </div>
);

export default memo(Loading);
