import { create as createHydrator } from "mobx-persist";
import { STORES } from "stores";

const hydrate = createHydrator({
  storage: localStorage,
  jsonify: true,
});

export const hydrateStores = async () => {
  await Promise.all(
    Object.entries(STORES).map(async ([key, store]) => {
      if (!store) return;
      await hydrate(key, store);

      if (store.afterHydration) {
        await store.afterHydration();
      }
    })
  );
};
