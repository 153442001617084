import React from "react";
import { Route, Redirect } from "react-router-dom";
import { appStore } from "stores/app";

const PrivateRoute = ({ component: Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() =>
        appStore.user ? <Component /> : <Redirect to="/sign-in" />
      }
    />
  );
};

export default PrivateRoute;
