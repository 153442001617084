import React, { Fragment, useEffect } from "react";
import { appStore } from "stores/app";
import cx from "classnames";
import AppMain from "../../Layout/AppMain";

function MainWrapper(props) {
  const {
    width,
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  useEffect(() => {
    appStore.setWidth(width);
  }, [width]);
  
  return (
    <Fragment>
      <div
        className={cx(
          "app-container app-theme-" + colorScheme,
          { "fixed-header": enableFixedHeader },
          { "fixed-sidebar": enableFixedSidebar || width < 1250 },
          { "fixed-footer": enableFixedFooter },
          { "closed-sidebar": enableClosedSidebar || width < 1250 },
          {
            "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
          },
          { "sidebar-mobile-open": enableMobileMenu },
          { "body-tabs-shadow-btn": enablePageTabsAlt }
        )}
      >
        <AppMain />
      </div>
    </Fragment>
  );
}

export default MainWrapper;
