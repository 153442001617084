import React from "react";
import { Route, Redirect } from "react-router-dom";
import { appStore } from "stores/app";

const PublicRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={() =>
      appStore.user && routeProps.restricted ? (
        <Redirect to="/dashboard" />
      ) : (
        <Component />
      )
    }
  />
);

export default PublicRoute;
