const { makeObservable, observable, action, computed } = require("mobx");

class GeoStore {
  countries = [];

  constructor() {
    makeObservable(this, {
      countries: observable,
      setCountries: action,
      HTMLSelectCountries: computed,
    });
  }

  setCountries(countries) {
    this.countries = countries;
  }

  get HTMLSelectCountries() {
    return GeoStoreFormatter.formatForHTMLSelect(this.countries);
  }
}

class GeoStoreFormatter {
  static formatForHTMLSelect(fetchedCountries) {
    if(fetchedCountries){
      return fetchedCountries.map((country) => {
        return {
          value: country.geonameId,
          label: country.countryName,
        };
      });
    }else{
      return [{
        value: '',
        label: '',
      }]
    }
  }
}

export const geoStore = new GeoStore();
