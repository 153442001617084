import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData } from "@sentry/integrations";

class SentryService {
  init() {
    Sentry.init({
      dsn: "https://84b5d903f30242d6bb621bf695b9b033@o4504711239892992.ingest.sentry.io/4504711298482176",
      integrations: [
        new BrowserTracing(),
        // defines depth of additional-data-object serialization
        new ExtraErrorData({ depth: 10 }),
      ],
      // undocumented param that is required for depth config to work
      normalizeDepth: 11,

      tracesSampleRate: 1.0,

      ignoreErrors: ["fb_xd_fragment", /^Exact Match Message$/],
      // ignoreErrors: [/PickleRick_\d\d/, 'RangeError'],
      beforeSend: (event) => (process.env.MODE === "production" ? event : null),
      beforeSendTransaction: (transaction) =>
        process.env.MODE === "production" ? transaction : null,
      enabled: process.env.MODE === "production",
    });
  }

  /**
   *
   * @param {*} errorData Javascript native error object, custom object or string
   * @param {*} config {
   *  transactionName: string,
   *  tags: array of { name: string, value: string
   * }
   */
  capture(errorData, config = {}) {
    try {
      Sentry.captureException(errorData, (scope) => {
        // set error name
        config.transactionName &&
          scope.setTransactionName(config.transactionName);

        // set custom tags
        config.tags &&
          config.tags.forEach((tag) => {
            // push every recieved custom tag to the scope
            scope.setTag(tag.name, tag.value);
          });

        return scope;
      });
    } catch (err) {
      console.error(err);
    }
  }
}

export const sentryService = new SentryService();
